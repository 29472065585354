<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <ValidationObserver ref="obs">
                    <fieldset class="elevation-1 rounded pa-6 fill-height">
                        <h3> {{ $t('tracks.track_repeatedness') }} </h3>
                        <v-row>
                            <v-col cols="12" md="6">
                                <TimePicker :label="$t('labels.occurrence_time')"
                                            name="start_time"
                                            :rules="`required|isDateTimeGreaterThanNow:${trackStartDate || ''}`"
                                            use-seconds
                                            v-model="startTime"></TimePicker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <TimePicker :label="$t('labels.end_time')"
                                            name="end_time"
                                            rules="required"
                                            use-seconds
                                            :disabled="!startTime"
                                            v-model="endTime"></TimePicker>
                            </v-col>
                        </v-row>
                        <h3> {{ $t('tracks.track_repeats') }} </h3>
                        <v-row>
                            <v-col cols="12" md="6">
                                <TextField
                                    :label="$t('labels.number_of_repetitions')"
                                    :rules="`minValue:1|maxValue:${maxRepetitions}|integer|required`"
                                    v-model="repetitionCount"
                                    @input="handleRepeatsChange($event)"
                                    :disabled="!endTime"
                                ></TextField>
                            </v-col>
                        </v-row>
                        <h3> {{ $t('tracks.track_step_config') }} </h3>
                        <v-row>
                            <v-col cols="12" md="6">
                                <SelectField
                                    :label="$t('messages.choose_one_type')"
                                    v-model="repetitionPattern"
                                    :defaultValue="trackStep[0]"
                                    :disabled="(repetitionCount <= 1)"
                                    class="w-max"
                                    :rules="repetitionCount <= 1 ? {} : {required: true}"
                                    :items="trackStep"></SelectField>
                            </v-col>
                        </v-row>
                        <h3> {{ $t('tracks.track_reminder_before') }} </h3>
                        <v-row>
                            <v-col cols="12" md="6">
                                <DurationPicker
                                    :label="$t('labels.track_remind_time')"
                                    v-model="timeToNotifyBeforeSequence"
                                ></DurationPicker>
                            </v-col>
                            <v-col cols="12" md="6">
                                <Checkbox v-model="notifyByEmail"
                                  class-name="mt-0 pt-2"
                                  :label="$t('labels.track_notify_by_email')"></Checkbox>
                            </v-col>
                        </v-row>
                    </fieldset>
                </ValidationObserver>
            </v-col>
            <v-col cols="12" md="6">
                <RepetitionCycleForm ref="repetitionCycle" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TextField from '@/components/widgets/forms/TextField.vue';
import SelectField from '@/components/widgets/forms/SelectField.vue';
import TimePicker from '@/components/widgets/forms/TimePicker.vue';
import { ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import DurationPicker from '@/components/widgets/forms/DurationPicker.vue';
import RepetitionCycleForm from '@/components/cyclicRevisions/manageTrack/RepetitionCycleForm.vue';
import {
    REPETITION_CYCLE_FULL_ENUM,
    REPETITION_PATTERN_ENUM,
    REPETITION_PATTERN_TRANSLATE_KEYS
} from '@/constants/cyclicRevision';
import Checkbox from '@/components/widgets/forms/Checkbox.vue';
import { GET_SECONDS_FROM_TIME, IS_CURRENT_DATE_LESS_OR_EQUAL } from '@/helpers/dates';

export default {
    name: 'TrackForm',
    components: {
        Checkbox,
        RepetitionCycleForm,
        DurationPicker,
        TextField,
        SelectField,
        TimePicker,
        ValidationObserver
    },
    data: () => ({
        REPETITION_PATTERN_ENUM
    }),
    computed: {
        ...mapFields('manageTrack', [
            'trackData.sequence.steps',
            'trackData.sequence.durationTime',
            'trackData.sequence.repetitionCount',
            'trackData.sequence.repetitionPattern',
            'trackData.startDate',
            'trackData.startTime',
            'trackData.endTime',
            'trackData.timeToNotifyBeforeSequence',
            'trackData.notifyByEmail',
            'trackData.repetitionDates',
            'trackData.repetitionScheme'
        ]),
        trackStep () {
            return [
                {
                    value: REPETITION_PATTERN_ENUM.EQUAL_BREAKS,
                    text: this.$t(REPETITION_PATTERN_TRANSLATE_KEYS[REPETITION_PATTERN_ENUM.EQUAL_BREAKS])
                }
                // TODO: Uncomment when random breaks will be fixed in backend
                // {
                //     value: REPETITION_PATTERN_ENUM.RANDOM_BREAKS,
                //     text: this.$t(REPETITION_PATTERN_TRANSLATE_KEYS[REPETITION_PATTERN_ENUM.RANDOM_BREAKS]),
                // }
            ];
        },
        maxRepetitions () {
            let timeDifference = 0;
            const startInSeconds = GET_SECONDS_FROM_TIME(this.startTime);
            const endInSeconds = GET_SECONDS_FROM_TIME(this.endTime);
            const sequenceInSeconds = this.durationTime ? this.durationTime.HH * 3600 + this.durationTime.mm * 60 : 0;

            if (endInSeconds <= startInSeconds) {
                timeDifference = (24 * 60 * 60) - startInSeconds + endInSeconds;
            } else {
                timeDifference = endInSeconds - startInSeconds;
            }

            return sequenceInSeconds ? Math.floor(timeDifference / sequenceInSeconds) : 0;
        },
        trackStartDate () {
            return this.repetitionScheme === REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS ? this.repetitionDates && [...this.repetitionDates].sort()?.[0] : this.startDate;
        }
    },
    methods: {
        IS_CURRENT_DATE_LESS_OR_EQUAL,
        async validate () {
            const trackForm = await this.$refs.obs.validate();
            const repetitionCycle = await this.$refs.repetitionCycle.validate();

            return trackForm && repetitionCycle;
        },
        handleRepeatsChange ($event) {
            if ($event <= 1) {
                this.repetitionPattern = REPETITION_PATTERN_ENUM.EQUAL_BREAKS;
            }
        }
    }
};
</script>

<style scoped>
    fieldset {
        border: none;
    }
    .w-max {
        max-width: 250px;
    }
</style>
