<template>
     <ValidationProvider :name="label" :rules="rules" :vid="name" v-slot="{ errors }" class="timepicker">
        <label v-if="label" for="timepicker" :class="{ 'timepickerLabel': true, 'error--text': errors.length }">
            {{ label }}
            <span v-if="isRequired" class="error--text">*</span>
        </label>
        <vue-timepicker
            v-bind="$attrs"
            :id="name"
            :name="name"
            ref="timepicker"
            :class="{'duration-picker': true, 'duration-picker--disabled': $attrs.disabled, 'duration-picker--hidden': $attrs.hidden, 'duration-picker--error': errors.length, 'is-dropdown-active': isDropdownActive}"
            drop-direction="up"
            @open="isDropdownActive = true"
            @close="isDropdownActive = false"
            v-on="!$attrs.disabled && $listeners"
            hide-dropdown
            append-to-body
            manual-input
            fixed-dropdown-button
            :format="useSeconds ? 'HH:mm:ss' : 'HH:mm'">
            <template v-slot:dropdownButton>
                <div class="v-input__append-inner"><div class="v-input__icon v-input__icon--append"><i aria-hidden="true" :class="`v-icon notranslate mdi mdi-chevron-down theme--light ${$attrs.disabled ? 'v-icon--disabled' : ''}`"></i></div></div>
            </template>
        </vue-timepicker>
         <div class="v-text-field__details" v-if="errors.length && label">
             <div class="v-messages theme--light error--text" role="alert">
                 <div class="v-messages__wrapper">
                     <div class="v-messages__message">{{ errors[0] }}</div>
                 </div>
             </div>
         </div>
     </ValidationProvider>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src';
import isFieldRequired from '@/mixins/isFieldRequired';
import { ICONS } from '@/constants/icons';
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'DurationPicker',
    components: { ValidationProvider, VueTimepicker },
    mixins: [isFieldRequired],
    props: {
        label: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': 'timepicker'
        },
        className: {
            type: [String, Array, Object],
            required: false
        },
        useSeconds: {
            type: Boolean,
            'default': false
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        clearable: {
            type: Boolean,
            'default': false
        },
        resetItem: {
            type: undefined,
            required: false
        },
        resetValues: {
            type: Array,
            required: false
        }
    },
    data: () => ({
        isDropdownActive: false,
        ICONS
    }),
    watch: {
        resetItem: {
            handler (val) {
                if (this.resetValues.includes(val)) {
                    this.clearTime();
                }
            }
        }
    },
    methods: {
        clearTime () {
            this.$refs.timepicker.clearTime();
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";

.timepicker {
    position: relative;
}

.timepickerLabel {
    line-height: 1;
    min-height: 8px;
    left: 12px;
    right: auto;
    position: absolute;
    transform: translateY(-8px) scale(0.75);
    transform-origin: left;
    background: white;
    z-index: 1;
    padding: 0 4px;
}

::v-deep {
    & .vue__time-picker {
        .controls {
            position: static;

            > * {
                position: static;
                padding: 0;
            }
        }
    }

    & .is-dropdown-active {
        .controls .v-icon {
            transform: rotate(180deg);
        }
    }

    & .duration-picker {
        max-width: 100%;
        min-width: 64px;
        width: 100%;
        height: 36px;
        display: flex;
        border-radius: 4px;
        border: 1px solid $light_grey_2;
        margin-bottom: 5px;
        padding: 0 12px;

        input {
            border: none;
            outline: none;
            box-shadow: none;
            height: 100%;
            flex-grow: 1;
            padding: 0 4px 0 0;

            &:focus {
                border: none;
                outline: none;
                box-shadow: none;
            }
        }
    }

    & .duration-picker--disabled {
        pointer-events: none;
        border: 1px solid rgba(0, 0, 0, 0.26);

        input {
            &::placeholder  {
                color: $text_lighter_2;
            }
        }

        & .controls > * {
            pointer-events: none;
        }
    }

    & .duration-picker--hidden {
        display: none
    }

    & .duration-picker--error {
        border: 2px solid $red;
    }
}
</style>
