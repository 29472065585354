<template>
    <div>
        <ValidationObserver ref="obs">
            <v-skeleton-loader
                v-if="isLoading"
                type="table"
                :types="{
                    'table-heading': '',
                    'table-thead': `heading@${headers.length}`,
                    'table-tbody': 'table-row@1',
                    'table-tfoot': '',
                    'table-row': `table-cell@${headers.length}`
                }">
            </v-skeleton-loader>
            <DraggableTable
                v-else
                :items="items"
                :headers="headers"
                :with-pagination="false"
                :is-draggable="editable"
                :is-actions="true"
                @drag-update="$emit('drag-update', $event)"
                @drag-started="handleStartDragging"
                @drag-ended="handleEndDragging">
                <template v-slot:item.lp="{ item }">
                    <span>{{ item.parent_id + 1 }}</span>
                </template>
                <template v-slot:item.title="{ item }">
                    <span>{{ item.name }}</span>
                </template>
                <template v-slot:item.id_type="{ item }">
                    <v-layout align-center>
                        <SelectField
                            :label="$t('tracks.table.task_type')"
                            rules="required"
                            :name="`type-${item.id}`"
                            :class="{'clickable': true}"
                            v-model="item.value"
                            :disabled="(type === null)"
                            :items="getOptions(item.elementType, item.id)"
                            @change="handleSelection($event, item)">
                        </SelectField>
                    </v-layout>
                </template>
                <template v-slot:item.time="{ item }">
                    <DurationPicker
                        ref="timeSinceSequenceStart"
                        :rules="`requiredDuration${item.parent_id ? '|' + `durationGreaterThan:@timepicker-step-${item.parent_id}` : '' }`"
                        :disabled="isDragging || type !== TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME"
                        :reset-item="type"
                        :reset-values="[TRACK_TYPE_ENUM.SEQ_IN_TIME_LIM, TRACK_TYPE_ENUM.RAND_IN_TIME]"
                        :name="`timepicker-step-${item.parent_id + 1}`"
                        v-model="item.timeSinceSequenceStart"
                        @change="handleInput($event, item, 'timeSinceSequenceStart')"
                    ></DurationPicker>
                </template>
                <template v-slot:item.tolerance="{ item }">
                    <DurationPicker
                        ref="tolerance"
                        :disabled="isDragging || type !== TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME"
                        :reset-item="type"
                        :reset-values="[TRACK_TYPE_ENUM.SEQ_IN_TIME_LIM, TRACK_TYPE_ENUM.RAND_IN_TIME]"
                        v-model="item.tolerance"
                        @change="handleInput($event, item, 'tolerance')"
                    ></DurationPicker>
                </template>
                <template v-slot:item.actions="{item}">
                    <v-row align="center"
                           justify="center">
                        <v-btn
                            icon
                            class="mr-2"
                            @click="$emit('copy', item)">
                            <v-icon size="20" class="clickable">{{ ICONS.COPY }}</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            class="mr-2"
                            @click="$emit('delete', item)">
                            <v-icon size="20" class="clickable">{{ ICONS.DELETE }}</v-icon>
                        </v-btn>
                    </v-row>
                </template>
            </DraggableTable>
            <div class="mt-3">
                <h3 class="font-weight-medium mb-3">{{ $t('labels.total_duration') }}:
                </h3>
                {{ timeDurationSummary }}
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';
import DraggableTable from '@/components/widgets/tables/DraggableTable.vue';
import DurationPicker from '@/components/widgets/forms/DurationPicker.vue';
import SelectField from '@/components/widgets/forms/SelectField.vue';
import { STEPS_TYPES_ENUM, TRACK_TYPE_ENUM, STEP_ELEMENT_TYPE_ENUM } from '@/constants/cyclicRevision';
import { ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import { GET_HUMANIZE_DURATION } from '@/helpers/dates';
import { mapState } from 'vuex';

export default {
    name: 'TrackConfigurationTable',
    components: { DraggableTable, SelectField, DurationPicker, ValidationObserver },
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        editable: {
            type: Boolean,
            'default': true
        },
        isLoading: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        trackConfigurations: [],
        timeLimitsDisabled: true,
        timeSummary: 0,
        isDragging: false,
        ICONS,
        TRACK_TYPE_ENUM
    }),
    computed: {
        ...mapFields('manageTrack', [
            'trackData.sequence.type',
            'trackData.sequence.durationTime'
        ]),
        ...mapState('manageTrack', [
            'inspectionsTemplates'
        ]),
        timeDurationSummary () {
            return GET_HUMANIZE_DURATION(this.timeSummary, this.currentLocale);
        }
    },
    methods: {
        handleInput (event, obj, field) {
            if (field === 'timeSinceSequenceStart') {
                obj.timeSinceSequenceStart = event.data;
            } else if (field === 'tolerance') {
                obj.tolerance = event.data;
            }
            this.recalculateDurationTime();
        },
        handleCopy (item) {
            this.$emit('copy', item);
        },
        handleDelete (item) {
            this.$emit('delete', item);
        },
        handleStartDragging () {
            this.isDragging = true;
        },
        handleEndDragging () {
            this.isDragging = false;
        },
        recalculateDurationTime () {
            if (this.type === TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME) {
                const lastItemWithTime = this.items.findLast((e) => e.timeSinceSequenceStart);
                this.timeSummary = lastItemWithTime ? this.formatDuration(lastItemWithTime.timeSinceSequenceStart) + this.formatDuration(lastItemWithTime.tolerance) : 0;

                this.durationTime = {
                    HH: Math.floor(this.timeSummary / 3600),
                    mm: (this.timeSummary % 3600) / 60
                };
            } else {
                this.timeSummary = this.durationTime?.HH * 3600 + this.durationTime?.mm * 60;
            }
        },
        formatDuration (time) {
            if (time === undefined) {
                return 0;
            }
            return time.HH * 3600 + time.mm * 60;
        },
        getOptions (type, pointId) {
            if (type === STEP_ELEMENT_TYPE_ENUM.POINT) {
                const templates = this.inspectionsTemplates.get(pointId);

                return [
                    {
                        value: STEPS_TYPES_ENUM.TEMPLATE,
                        text: 'TEMPLATE'
                    },
                    ...templates || []
                ];
            }

            return [
                {
                    value: STEPS_TYPES_ENUM.QR,
                    text: 'QR'
                },
                {
                    value: STEPS_TYPES_ENUM.NFC,
                    text: 'NFC'
                }
            ];
        },
        handleSelection (event, obj) {
            if (typeof event === 'number') {
                obj.verificationWay = STEPS_TYPES_ENUM.TEMPLATE;
                obj.template = { id: event };
            } else {
                obj.verificationWay = event;
                obj.template = { id: null };
            }
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";

::v-deep {
    & .v-select {
        margin-top: 5px;
        min-width: 100px;

        & .v-text-field__details {
            display: none;
        }
    }
}

</style>
