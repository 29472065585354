<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-data-table disable-sort
                      :headers="localHeaders"
                      :items="localItems"
                      v-bind="$attrs"
                      v-on="$listeners"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>
            <template v-slot:body="props">
                <draggable
                    :list="props.items"
                    tag="tbody"
                    :handle="dragHandler"
                    @update="handleTableUpdate(props.items)"
                    @start="handleStartDragging"
                    @end="handleEndDragging">
                    <template v-if="$vuetify.breakpoint.smAndUp">
                        <tr v-for="(rowItem, index) in props.items"
                            :key="index">
                            <td v-if="isDraggable">
                                <v-icon size="20"
                                        class="clickable">{{ ICONS.DRAG }}
                                </v-icon>
                            </td>
                            <td v-for="(headerItem, i) in headers"
                                :key="i">
                                <slot :name="`item.${headerItem.value}`"
                                      :item="rowItem">
                                    {{ rowItem[headerItem.value] }}
                                </slot>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="(rowItem, index) in props.items"
                            :key="index">
                            <td>
                                <div v-if="isDraggable">
                                    <v-icon size="20"
                                            class="clickable">{{ ICONS.DRAG }}
                                    </v-icon>
                                </div>
                                <div v-for="(headerItem, i) in headers"
                                     class="mb-4"
                                     :key="i">
                                    <v-layout align-center
                                              justify-space-between>
                                        <v-flex xs5 class="shrink">
                                            <span class="font-weight-medium">{{ headerItem.text }}</span>
                                        </v-flex>
                                        <v-flex class="pl-2">
                                            <v-layout justify-end class="text-right">
                                                <slot :name="`item.${headerItem.value}`"
                                                      :item="rowItem">
                                                    {{ rowItem[headerItem.value] }}
                                                </slot>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </td>
                        </tr>
                    </template>
                </draggable>
            </template>
        </v-data-table>

        <Pagination :pagination="pagination"
                    v-if="withPagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import Pagination from '@/components/widgets/tables/Pagination';
import draggable from 'vuedraggable';

export default {
    name: 'DraggableTable',
    components: { SortableHeader, Pagination, draggable },
    props: {
        headers: {
            type: Array,
            required: true
        },
        changedPage: {
            type: [String, Number],
            required: false
        },
        amountAll: {
            type: [String, Number],
            'default': 0
        },
        items: {
            type: Array,
            'default': () => ([])
        },
        withPagination: {
            type: Boolean,
            'default': true
        },
        isDraggable: {
            type: Boolean,
            'default': true
        }
    },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        localItems: [],
        ICONS
    }),
    computed: {
        dragHandler () {
            return this.isDraggable ? null : '.handle';
        },
        localHeaders () {
            return this.isDraggable
                ? [
                    {
                        text: '',
                        value: 'drag_icon',
                        width: '30px'
                    },
                    ...this.headers
                ]
                : this.headers;
        }
    },
    watch: {
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        },
        amountAll: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        items: {
            handler (val) {
                this.localItems = [...val];
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            this.$emit('sort', event);
        },
        handleTableUpdate (items) {
            this.$emit('drag-update', items);
        },
        handleStartDragging () {
            this.$emit('drag-started');
        },
        handleEndDragging () {
            this.$emit('drag-ended');
        }
    }
};
</script>

<style scoped>

</style>
