<template>
    <div class="mx-2 field">
        <TextField
            v-model="cycleFrequencyInDays"
            hide-details="auto"
            rules="required|integer"
            :label="$t('tracks.block_repeats_days_label')"
        ></TextField>
    </div>
</template>

<script>
import TextField from '@/components/widgets/forms/TextField.vue';
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'TimeRangeRepetition',
    components: {
        TextField
    },
    computed: {
        ...mapFields('manageTrack', [
            'trackData.cycleFrequencyInDays'
        ])
    }
};
</script>

<style scoped>
    .field {
        min-width: 50px;
    }
</style>
