import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        searchTypes: null,
        isTypesLoading: false,
        debounceGetTypes: null,
        typesPaginationPage: 1
    }),
    computed: {
        ...mapState('incidents', [
            'totalIncidentTypesAmount'
        ]),
        ...mapGetters('incidents', [
            'filterableTypesList'
        ])
    },
    watch: {
        searchTypes: {
            handler (val, oldVal) {
                if (!val || this.isTypesLoading || val === oldVal) {
                    return;
                }

                this.debounceGetTypes(val);
            }
        }
    },
    methods: {
        getIncidentsTypes (additionalParams = null, searchID = null) {
            this.isTypesLoading = true;

            let params = {};

            if (searchID) {
                params['filter[id]'] = searchID;
            }

            if (typeof additionalParams === 'string') {
                params['filter[name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('incidents/getIncidentTypes', params)
                .then(() => {
                    this.typesPaginationPage = 1;
                })
                .finally(() => {
                    this.isTypesLoading = false;
                });
        },
        getIncidentsTypesLazyLoading (additionalParams = null) {
            if (this.totalIncidentTypesAmount > this.filterableTypesList.length) {
                this.isTypesLoading = true;

                this.typesPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.typesPaginationPage;

                const _name = this.searchTypes || this.filtersSearching?.[FILTERS_SEARCHING.INCIDENT_TYPE];

                if (_name) {
                    params['filter[name][match]'] = _name;
                }

                return this.$store.dispatch('incidents/getIncidentTypesLazyLoading', params)
                    .finally(() => {
                        this.isTypesLoading = false;
                    });
            }
        },
        referralTypeCallback (newVal) {
            if (newVal && newVal.length > 0) {
                this.filtersConfig.find(el => el.model === FILTERS.INCIDENT_STATUS_ID).disabled = false;
                this.filtersConfig.find(el => el.model === FILTERS.INCIDENT_STATUS_ID).successMessages = '';
                this.getIncidentStatuses();
            } else {
                this.filtersConfig.find(el => el.model === FILTERS.INCIDENT_STATUS_ID).disabled = true;
                this.filtersConfig.find(el => el.model === FILTERS.INCIDENT_STATUS_ID).successMessages = this.$t('messages.choose_incident_type');

                this.$refs.filtersBar.resetSpecifyFilter(FILTERS.INCIDENT_STATUS_ID);
            }
            this.$store.commit('filters/SET_CONFIG', this.filtersConfig);
        }
    },
    created () {
        this.debounceGetTypes = debounce(this.getIncidentsTypes, 1000);
    }
};
