<template>
    <div class="ml-2" v-if="repetition === REPETITION_TYPE_ENUM.WEEKLY">
        <Autocomplete
            v-model="repetitionWeekdays"
            hide-details="auto"
            :items="weekDays"
            :multiple="true"
            :label="$t('tracks.block_repeats_weekly_label')"
            rules="required"
        ></Autocomplete>
    </div>
    <div class="ml-2" v-else-if="repetition === REPETITION_TYPE_ENUM.YEARLY">
        <DatePicker
            name="start_date"
            rules="required"
            :clearable="true"
            :multiple="true"
            :no-title="true"
            :label="$t('tracks.block_repeats_yearly_label')"
            v-model="repetitionDates"
            :min="TODAY_STRING"
            :format="yearlyFormat"></DatePicker>
        <v-chip
            v-for="(selection, i) in repetitionDates"
            :key="selection.text"
            close @click:close="repetitionDates.splice(i, 1)"
            class="mr-2 mb-2"
        >
            {{ PARSE_DATE(repetitionDates[i]) }}
        </v-chip>
    </div>
</template>

<script>
import Autocomplete from '@/components/widgets/forms/Autocomplete.vue';
import DatePicker from '@/components/widgets/forms/DatePicker.vue';
import { mapFields } from 'vuex-map-fields';
import { REPETITION_TYPE_ENUM, WEEKDAYS_TRANSLATE_KEYS_ARRAY } from '@/constants/cyclicRevision';
import { PARSE_DATE, TODAY_STRING } from '@/helpers/dates';
import { mapState } from 'vuex';

export default {
    name: 'RepetitionDates',
    methods: { PARSE_DATE },
    data: () => ({
        yearlyFormat: 'dd MMM',
        TODAY_STRING
    }),
    props: {
        repetition: {
            type: String,
            'default': REPETITION_TYPE_ENUM.WEEKLY
        }
    },
    components: {
        Autocomplete,
        DatePicker
    },
    computed: {
        REPETITION_TYPE_ENUM () {
            return REPETITION_TYPE_ENUM;
        },
        ...mapFields('manageTrack', [
            'trackData.repetitionWeekdays',
            'trackData.repetitionDates'

        ]),
        ...mapState('locale', [
            'currentLocale'
        ]),
        weekDays () {
            return [
                {
                    value: 0,
                    text: this.$t(WEEKDAYS_TRANSLATE_KEYS_ARRAY[0])
                },
                {
                    value: 1,
                    text: this.$t(WEEKDAYS_TRANSLATE_KEYS_ARRAY[1])
                },
                {
                    value: 2,
                    text: this.$t(WEEKDAYS_TRANSLATE_KEYS_ARRAY[2])
                },
                {
                    value: 3,
                    text: this.$t(WEEKDAYS_TRANSLATE_KEYS_ARRAY[3])
                },
                {
                    value: 4,
                    text: this.$t(WEEKDAYS_TRANSLATE_KEYS_ARRAY[4])
                },
                {
                    value: 5,
                    text: this.$t(WEEKDAYS_TRANSLATE_KEYS_ARRAY[5])
                },
                {
                    value: 6,
                    text: this.$t(WEEKDAYS_TRANSLATE_KEYS_ARRAY[6])
                }
            ];
        }
    }
};
</script>

<style scoped>

</style>
