<template>
    <div>
        <v-container class="pb-0">
            <FiltersBar ref="filtersBar" alwaysMinified :minWidth="6"></FiltersBar>
        </v-container>
        <v-treeview
            v-model="selected"
            :items="employeesTree"
            :return-object="true"
            selected-color="primary"
            item-disabled="locked"
            open-on-click
            selectable
            indeterminate-icon="mdi-bookmark-minus">
        </v-treeview>
    </div>
</template>

<script>
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import debounce from 'lodash.debounce';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import debouncedEmployeesGroups from '@/mixins/debounced/debouncedEmployeesGroups';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import { ICONS } from '@/constants/icons';
import filtering from '@/mixins/filtering';
import FiltersBar from '@/components/widgets/tables/FiltersBar.vue';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'EmployeesTree',
    mixins: [
        filtering,
        debouncedEmployees,
        debouncedEmployeesGroups,
        debouncedCustomers,
        debouncedSites,
        debouncedLocations
    ],
    components: { FiltersBar },
    props: {
        employeesTree: Array,
        initiallySelected: Array
    },
    data: () => ({
        selected: [],
        ICONS,
        debouncedFetchEmployees: null,
        pagination: {
            page: 1,
            amount: 20
        },
        initialed: false
    }),
    computed: {
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        ...mapFields('search', [
            'search'
        ]),
        ...mapState('manageTrack', [
            'trackData'
        ]),
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.EMPLOYEE_GROUP_ID,
                    label: this.$t('navigation.employees_groups'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.EMPLOYEES_GROUPS_NAME,
                    lazy: this.getEmployeesGroupsLazyLoading,
                    searchCallback: this.debouncedGetEmployeesGroups,
                    items: {
                        obj: 'getters',
                        module: 'employeesGroups',
                        prop: 'filterableEmployeesGroups'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.CUSTOMER_ID,
                    label: this.$t('labels.customer'),
                    loading: false,
                    multiple: true,
                    search: FILTERS_SEARCHING.CUSTOMER,
                    lazy: this.getCustomersLazyLoading,
                    referralCallback: this.referralCustomerCallback,
                    searchCallback: this.debouncedGetCustomers,
                    items: {
                        obj: 'getters',
                        module: 'customers',
                        prop: 'listingCustomers'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.SITE_ID,
                    label: this.$t('labels.site'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_customer'),
                    search: FILTERS_SEARCHING.SITE_PER_CUSTOMER,
                    lazy: this.getSitesLazyLoading,
                    referralCallback: this.referralSiteCallback,
                    searchCallback: this.debouncedGetSites,
                    searchOptions: {
                        reference: FILTERS.CUSTOMER_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'sites',
                        prop: 'sites'
                    }
                }),
                new AutocompleteFilter({
                    model: FILTERS.LOCATION_ID,
                    label: this.$t('labels.location'),
                    loading: false,
                    multiple: true,
                    disabled: true,
                    successMessages: this.$t('messages.choose_site'),
                    search: FILTERS_SEARCHING.LOCATION,
                    lazy: this.getLocationsLazyLoading,
                    searchCallback: this.debouncedGetLocations,
                    searchOptions: {
                        reference: FILTERS.SITE_ID
                    },
                    items: {
                        obj: 'getters',
                        module: 'locations',
                        prop: 'locations'
                    }
                })
            ];
        }
    },
    watch: {
        selected: {
            handler (val, oldVal) {
                if (JSON.stringify(val) === JSON.stringify(oldVal) && val.length !== 0) return;
                this.$store.dispatch('cyclicRevisions/setSelectedTracksEmployees', val);
            }
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.debouncedFetchEmployees();
                }
            }
        },
        initiallySelected: {
            handler (val) {
                if (this.initialed) return;

                this.selected = val;
                this.initialed = true;
            }
        }
    },
    methods: {
        removeSelected (item) {
            this.selected = this.selected.filter(i => i.id !== item.id);
        },
        generateFilters () {
            const params = {};

            this.filtersConfig.forEach(filter => {
                const f = filter.model;
                if ([FILTERS.SITE_ID, FILTERS.CUSTOMER_ID].includes(f)) {
                    return;
                }
                if (this.filters[f] && this.filters[f].length > 0) {
                    params[`filter[${f}]`] = this.filters[f].join(',');
                }
            });

            return params;
        },
        fetchEmployees () {
            const params = {
                'filter[is_item]': false,
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                ...this.generateFilters()
            };

            if (this.search) {
                params['filter[search]'] = this.search;
            }
            this.$emit('get-employees', params);
        },
        setFiltersWatcher (callback) {
            this.localConfig.forEach(filter => {
                const el = filter.model;
                this.$watch(`${this.filterPropertyName}.${el}`, function (newVal) {
                    if (newVal) {
                        if (this.pagination) {
                            this.pagination.page = 1;
                        }
                        callback();
                        if (filter.hasProperty('referralCallback')) {
                            filter.referralCallback(newVal);
                        }
                    } else if (newVal === null) {
                        callback();
                    }
                });
            });
        }
    },
    mounted () {
        this.$store.commit('filters/SET_CONFIG', this.filtersConfig);
        this.$store.dispatch('employeesGroups/getEmployeesGroups')
            .then(() => {
                this.filtersConfig.find(el => el.model === FILTERS.EMPLOYEE_GROUP_ID).loading = false;
                this.$store.commit('filters/SET_CONFIG', this.filtersConfig);
            });
        this.debouncedFetchEmployees = debounce(this.fetchEmployees, 1000);
        this.setFiltersWatcher(this.debouncedFetchEmployees);
    },

    beforeUnmount () {
        this.$store.dispatch('filters/clearFiltersParams');
        this.search = null;
        this.debouncedFetchEmployees = null;
    }
};
</script>

<style scoped>
    ::v-deep {
         .v-treeview-node--disabled {
            display: none;
         }
    }
</style>
