import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';
import { FILTERS_SEARCHING } from '@/constants/filtersModelNames';

export default {
    data: () => ({
        debouncedGetEmployeesForSelect: null,
        isEmployeesForSelectLoading: false,
        searchEmployeesForSelect: null,
        employeesPaginationPageForSelect: 1
    }),
    computed: {
        ...mapState('employees', [
            'totalEmployeesAmountForSelect',
            'employeeGroupForSelect'
        ]),
        ...mapGetters('employees', [
            'employeesForSelect'
        ]),
        defaultParams () {
            return {
                'filter[is_item]': false
            };
        }
    },
    watch: {
        searchEmployeesForSelect: {
            handler (val) {
                if (!val || this.isEmployeesForSelectLoading) {
                    return;
                }

                this.debouncedGetEmployeesForSelect(val);
            }
        },
        employeeGroupForSelect: {
            handler (val) {
                this.defaultParams['filter[employee_group_id]'] = val;
            }
        }
    },
    methods: {
        getEmployeesForSelect (additionalParams = null) {
            this.isEmployeesForSelectLoading = true;

            let params = { ...this.defaultParams };

            if (typeof additionalParams === 'string') {
                params['filter[full_name][match]'] = additionalParams;
            } else if (typeof additionalParams === 'object' && additionalParams !== null) {
                params = { ...params, ...additionalParams };
            }
            return this.$store.dispatch('employees/getEmployeesForSelect', params)
                .then(() => {
                    this.employeesPaginationPageForSelect = 1;
                })
                .finally(() => {
                    this.isEmployeesForSelectLoading = false;
                });
        },
        getEmployeesLazyLoadingForSelect (additionalParams = null) {
            if (this.totalEmployeesAmountForSelect > this.employeesForSelect.length) {
                this.isEmployeesForSelectLoading = true;

                this.employeesPaginationPageForSelect += 1;

                let params = { ...this.defaultParams };

                if (typeof additionalParams === 'object' && additionalParams !== null) {
                    params = { ...params, ...additionalParams };
                }

                params['page[number]'] = this.employeesPaginationPageForSelect;

                const _name = this.searchEmployeesForSelect || this.filtersSearching?.[FILTERS_SEARCHING.EMPLOYEES];

                if (_name) {
                    params['filter[full_name][match]'] = _name;
                }

                return this.$store.dispatch('employees/getEmployeesLazyLoadingForSelect', params)
                    .finally(() => {
                        this.isEmployeesForSelectLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetEmployeesForSelect = debounce(this.getEmployeesForSelect, 1000);
    }
};
