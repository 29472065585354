var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"obs"},[(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table","types":{
                'table-heading': '',
                'table-thead': ("heading@" + (_vm.headers.length)),
                'table-tbody': 'table-row@1',
                'table-tfoot': '',
                'table-row': ("table-cell@" + (_vm.headers.length))
            }}}):_c('DraggableTable',{attrs:{"items":_vm.items,"headers":_vm.headers,"with-pagination":false,"is-draggable":_vm.editable,"is-actions":true},on:{"drag-update":function($event){return _vm.$emit('drag-update', $event)},"drag-started":_vm.handleStartDragging,"drag-ended":_vm.handleEndDragging},scopedSlots:_vm._u([{key:"item.lp",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.parent_id + 1))])]}},{key:"item.title",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.id_type",fn:function(ref){
            var item = ref.item;
return [_c('v-layout',{attrs:{"align-center":""}},[_c('SelectField',{class:{'clickable': true},attrs:{"label":_vm.$t('tracks.table.task_type'),"rules":"required","name":("type-" + (item.id)),"disabled":(_vm.type === null),"items":_vm.getOptions(item.elementType, item.id)},on:{"change":function($event){return _vm.handleSelection($event, item)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)]}},{key:"item.time",fn:function(ref){
            var item = ref.item;
return [_c('DurationPicker',{ref:"timeSinceSequenceStart",attrs:{"rules":("requiredDuration" + (item.parent_id ? '|' + "durationGreaterThan:@timepicker-step-" + (item.parent_id) : '')),"disabled":_vm.isDragging || _vm.type !== _vm.TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME,"reset-item":_vm.type,"reset-values":[_vm.TRACK_TYPE_ENUM.SEQ_IN_TIME_LIM, _vm.TRACK_TYPE_ENUM.RAND_IN_TIME],"name":("timepicker-step-" + (item.parent_id + 1))},on:{"change":function($event){return _vm.handleInput($event, item, 'timeSinceSequenceStart')}},model:{value:(item.timeSinceSequenceStart),callback:function ($$v) {_vm.$set(item, "timeSinceSequenceStart", $$v)},expression:"item.timeSinceSequenceStart"}})]}},{key:"item.tolerance",fn:function(ref){
            var item = ref.item;
return [_c('DurationPicker',{ref:"tolerance",attrs:{"disabled":_vm.isDragging || _vm.type !== _vm.TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME,"reset-item":_vm.type,"reset-values":[_vm.TRACK_TYPE_ENUM.SEQ_IN_TIME_LIM, _vm.TRACK_TYPE_ENUM.RAND_IN_TIME]},on:{"change":function($event){return _vm.handleInput($event, item, 'tolerance')}},model:{value:(item.tolerance),callback:function ($$v) {_vm.$set(item, "tolerance", $$v)},expression:"item.tolerance"}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('copy', item)}}},[_c('v-icon',{staticClass:"clickable",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.COPY))])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('v-icon',{staticClass:"clickable",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.ICONS.DELETE))])],1)],1)]}}])}),_c('div',{staticClass:"mt-3"},[_c('h3',{staticClass:"font-weight-medium mb-3"},[_vm._v(_vm._s(_vm.$t('labels.total_duration'))+": ")]),_vm._v(" "+_vm._s(_vm.timeDurationSummary)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }