<template>
    <fieldset class="elevation-1 rounded pa-6 fill-height">
        <h3> {{ $t('tracks.block_config_title') }} </h3>
        <ValidationObserver ref="repetitionRadio">
            <ValidationProvider
                :name="$t('tracks.block_config_title')"
                vid="repetitionRadio"
                rules="required"
                v-slot="{ errors }"
            >
                <v-radio-group v-model="repetitionRadio" row :error-messages="errors">
                    <v-radio
                        v-for="(item, i) in repetitionRadioConfig"
                        :key="i"
                        :label="item.text"
                        :value="item.value"
                        />
                </v-radio-group>
            </ValidationProvider>
        </ValidationObserver>
        <ValidationObserver ref="timeRangeRepetitionObs" v-if="repetitionRadio === REPETITION_CYCLE_FULL_ENUM.CYCLE">
            <v-row>
                <v-col cols="12" md="6">
                    <DatePicker
                        :label="$t('labels.start_date')"
                        name="block_start_date"
                        rules="isDateGreaterOrEqualNow|required"
                        :clearable="true"
                        hide-details="auto"
                        :min="TODAY_STRING"
                        v-model="startDate"
                    />
                </v-col>
                <v-col cols="12" md="6">
                    <DatePicker
                        :label="$t('labels.end_date')"
                        rules="isDateGreaterOrEqual:@block_start_date|required"
                        name="block_end_date"
                        :clearable="true"
                        hide-details="auto"
                        :min="GET_LATER_DATE(startDate, TODAY_STRING)"
                        v-model="endDate"
                    />
                </v-col>
                <v-col cols="12">
                    <ValidationProvider
                        :name="$t('tracks.repetition_cycle_title')"
                        vid="repetitionCycleRadio"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <v-radio-group v-model="repetitionCycleRadio" class="mt-0 pt-0" :error-messages="errors">
                            <v-radio
                                v-for="(item, i) in repetitionCycleRadioConfig"
                                :key="i"
                                :value="item.value"
                            >
                                <template v-slot:label>
                                    <span>{{item.text}}</span>
                                    <template  v-if="repetitionCycleRadio === item.value">
                                        <component :is="item.component"></component>
                                        <span v-if="item.suffixText">{{ item.suffixText }}</span>
                                    </template>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </ValidationProvider>
                </v-col>
            </v-row>
        </ValidationObserver>
        <ValidationObserver ref="datesRepetitionObs" v-else-if="repetitionRadio === REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS">
            <v-row>
                <v-col>
                    <RepetitionDays :repetition="REPETITION_TYPE_ENUM.YEARLY" />
                </v-col>
            </v-row>
        </ValidationObserver>
    </fieldset>
</template>

<script>
import TimeRangeRepetition from '@/components/cyclicRevisions/manageTrack/TimeRangeRepetition.vue';
import DatePicker from '@/components/widgets/forms/DatePicker.vue';
import RepetitionDays from '@/components/cyclicRevisions/manageTrack/RepetitionDays.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
    REPETITION_CYCLE_FULL_ENUM, REPETITION_CYCLE_TRANSLATE_KEYS,
    REPETITION_TYPE_ENUM
} from '@/constants/cyclicRevision';
import { mapFields } from 'vuex-map-fields';
import RadioGroup from '@/components/widgets/forms/RadioGroup.vue';
import { GET_LATER_DATE, TODAY_STRING } from '@/helpers/dates';

export default {
    name: 'RepetitionCycleForm',
    components: {
        RadioGroup,
        ValidationProvider,
        DatePicker,
        ValidationObserver,
        TimeRangeRepetition,
        RepetitionDays
    },
    data: () => ({
        blockSubType: null,
        repetitionRadio: null,
        repetitionCycleRadio: null,
        TODAY_STRING
    }),
    computed: {
        ...mapFields('manageTrack', [
            'trackData.startDate',
            'trackData.endDate',
            'trackData.repetitionScheme'
        ]),
        REPETITION_TYPE_ENUM () {
            return REPETITION_TYPE_ENUM;
        },
        REPETITION_CYCLE_FULL_ENUM () {
            return REPETITION_CYCLE_FULL_ENUM;
        },
        repetitionRadioConfig () {
            return [
                {
                    value: REPETITION_CYCLE_FULL_ENUM.CYCLE,
                    text: this.$t(REPETITION_CYCLE_TRANSLATE_KEYS[REPETITION_CYCLE_FULL_ENUM.CYCLE])
                },
                {
                    value: REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS,
                    text: this.$t(REPETITION_CYCLE_TRANSLATE_KEYS[REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS])
                }
            ];
        },
        repetitionCycleRadioConfig () {
            return [
                {
                    value: REPETITION_CYCLE_FULL_ENUM.CYCLE_PER_X_DAYS,
                    text: this.$t(REPETITION_CYCLE_TRANSLATE_KEYS[REPETITION_CYCLE_FULL_ENUM.CYCLE_PER_X_DAYS]),
                    component: TimeRangeRepetition,
                    suffixText: this.$t('tracks.block_repeats_days')
                },
                {
                    value: REPETITION_CYCLE_FULL_ENUM.WEEKLY_CYCLE,
                    text: this.$t(REPETITION_CYCLE_TRANSLATE_KEYS[REPETITION_CYCLE_FULL_ENUM.WEEKLY_CYCLE]),
                    component: RepetitionDays
                }
            ];
        }
    },
    methods: {
        GET_LATER_DATE,
        async validate () {
            const repetitionRadioRef = await this.$refs.repetitionRadio.validate();
            let blockRepeatability = false;
            if (this.repetitionRadio === REPETITION_CYCLE_FULL_ENUM.CYCLE) {
                blockRepeatability = await this.$refs.timeRangeRepetitionObs.validate();
            } else if (this.repetitionRadio === REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS) {
                blockRepeatability = await this.$refs.datesRepetitionObs.validate();
            }
            return repetitionRadioRef && blockRepeatability;
        },
        selectRadios () {
            if (this.repetitionScheme !== null) {
                this.repetitionRadio = this.repetitionScheme === REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS
                    ? REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS
                    : REPETITION_CYCLE_FULL_ENUM.CYCLE;
                this.repetitionCycleRadio = this.repetitionCycleRadioConfig.find(radio => radio.value === this.repetitionScheme)?.value || null;
            }
        }
    },
    watch: {
        repetitionRadio (val) {
            if (val === REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS) {
                this.repetitionScheme = REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS;
            } else {
                this.repetitionScheme = this.repetitionCycleRadio?.value || null;
            }
        },
        repetitionCycleRadio (val) {
            if (val) this.repetitionScheme = val;
        },
        repetitionScheme () {
            this.selectRadios();
        }
    },
    created () {
        this.selectRadios();
    }
};
</script>
