<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-menu v-model="menu"
            ref="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            format="24hr"
            min-width="290px">
        <template v-slot:activator="{ on, attrs }">
            <ValidationProvider :name="label"
                                :vid="name"
                                :rules="rules"
                                v-slot="{ errors }">
                <v-text-field
                    v-model="time"
                    readonly
                    outlined
                    autocomplete="off"
                    :error-messages="errors"
                    :disabled="$attrs.disabled"
                    :class="className"
                    v-bind="attrs"
                    v-on="on">
                    <template slot="label">
                        {{ label }}
                        <span v-if="isRequired" class="error--text">*</span>
                    </template>
                    <template slot="append">
                        <v-icon size="18"
                                class="clickable mr-1"
                                @click="clearTime"
                                v-if="showClearButton">{{ ICONS.CLOSE }}</v-icon>
                        <v-icon class="clickable" v-on="on">{{ ICONS.CLOCK }}</v-icon>
                    </template>
                </v-text-field>
            </ValidationProvider>
        </template>
        <v-time-picker
            v-bind="$attrs"
            v-on="$listeners"
            v-model="time"
            full-width
            :use-seconds="useSeconds"
            @click:second="saveSeconds(time)"
            @click:minute="saveMinutes(time)"></v-time-picker>
    </v-menu>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import isFieldRequired from '@/mixins/isFieldRequired';
import { ICONS } from '@/constants/icons';

export default {
    name: 'TimePicker',
    components: { ValidationProvider },
    mixins: [isFieldRequired],
    props: {
        label: {
            type: String,
            'default': ''
        },
        name: {
            type: String,
            'default': ''
        },
        className: {
            type: [String, Array, Object],
            required: false
        },
        useSeconds: {
            type: Boolean,
            'default': false
        },
        rules: {
            type: [String, Object, Array],
            'default': ''
        },
        clearable: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        menu: false,
        ICONS
    }),
    computed: {
        showClearButton () {
            return this.clearable && this.$attrs.value;
        },
        time: {
            get () {
                return this.$attrs.value;
            },
            set (val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        saveMinutes (time) {
            if (!this.useSeconds) {
                this.$refs.menu.save(time);
            }
        },
        saveSeconds (time) {
            if (this.useSeconds) {
                this.$refs.menu.save(time);
            }
        },
        clearTime () {
            this.$refs.menu.save(null);
            this.$emit('input', null);
        }
    }
};
</script>

<style scoped>

</style>
